<mat-toolbar class="mat-elevation-z6">
  <a routerLink="/">
    <img src="./assets/ea-logo.png" />
  </a>
  <div>
    <div class="title">{{title$ | async}}</div>
    <div class="subtitle caption">{{subTitle}}</div>
  </div>
  <div class="header-spacer"></div>
  @for (item of (headerMenu2FromRoot$ | async); track $index) {
    <app-header-menu [menuItem]="item">
    </app-header-menu>
  }
</mat-toolbar>