import { SpRequestBodyInputParameter } from "./sp-request-body-input-parameter.models"

export class ExecuteSpRequest {
  spName: string;
  withoutPermission: boolean;
  inputParameters: SpRequestBodyInputParameter[];

  constructor(spName: string, withoutPermission: boolean) {
    this.spName = spName;
    this.withoutPermission = withoutPermission;
    this.inputParameters = new Array<SpRequestBodyInputParameter>();
  }
}
