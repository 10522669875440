import { Component } from '@angular/core';
import { AppConstants } from 'src/app/app/helpers/app-constants';
import { AppFacade } from 'src/app/app/store/app.facade';
import { AuthFacade } from 'src/app/auth/store/auth.facade';
import { DynamicUiService } from 'src/app/dynamic-ui/services/dynamic-ui.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    title$ = this.appFacade.title$;
    headerMenu2FromRoot$ = this.authFacade.selectHeaderMenu2FromRoor$;
    clubName: string = '';
    anlassName: string = '';
    subTitle: string = '';

    constructor(
        private appFacade: AppFacade,
        private authFacade: AuthFacade,
        private dynamicService: DynamicUiService
    ) {
        this.dynamicService.GetValueAsObservable(
            AppConstants.dataStoreSelectedClubName
        ).subscribe(c => {
            this.clubName = c;
            this.setSubTitle();
        });
        this.dynamicService.GetValueAsObservable(
            AppConstants.dataStoreSelectedAnlassName
        ).subscribe(c => {
            this.anlassName = c;
            this.setSubTitle();
        });

    }

    setSubTitle() {
        this.subTitle = ((this.clubName) ? this.clubName : "") + ((this.anlassName) ? " - " + this.anlassName : "");
    }
}
