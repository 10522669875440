@if (!menuItem.ParentId){
<button mat-icon-button [matMenuTriggerFor]="headerMenu" class="menu-icon">
  @if (menuItem.Icon){
  <mat-icon>{{menuItem.Icon}}</mat-icon>
  }
</button>
<mat-menu #headerMenu="matMenu">
  @for (menuChildItem of GetChilds(menuItem) |async; track $index) {
  <app-header-menu [menuItem]="menuChildItem">
  </app-header-menu>
  }
</mat-menu>}
@else {
@if (menuItem.Type == 1){

}
@else if (menuItem.Type == 2)
{
<button mat-menu-item [routerLink]="menuItem.Url" routerLinkActive="link-active">
  <span>
    {{menuItem.DisplayName}}
  </span>
</button>
}
@else if (menuItem.Type == 3)
{
<mat-divider></mat-divider>
}
@else if (menuItem.Type == 4)
{
<p class="center">
  Willkommen<br />
  {{(loggedInUser$ | async)?.firstName}} {{(loggedInUser$ | async)?.lastName}}
  <br />
  <span class="mat-small">{{(loggedInUser$ | async)?.mail}}</span>
</p>
}
@else if (menuItem.Type == 5)
{
<p class="center margin-top-1">
  <span class="mat-small margin-left-2 margin-right-2">
    Version V 1.0 (Build xxx)
  </span>
</p>
}
@else if (menuItem.Type == 6)
{
<button mat-menu-item (click)="onLogout()">
  <span>
    {{menuItem.DisplayName}}
  </span>
</button>
}
}


<!--
<ng-container *ngIf="(this.headerMenus$ | async).length > 0">
  <button mat-icon-button [matMenuTriggerFor]="headerMenuConfig" class="menu-icon"
    aria-label="Header menu config">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #headerMenuConfig="matMenu">
    <ng-container *ngFor="let menu of (this.headerMenus$ | async)">
      <a *ngIf="menu.url != '-'" mat-menu-item [routerLink]="menu.url" routerLinkActive="link-active"
        [routerLinkActiveOptions]="{ exact: true }">
        <span>{{menu.displayName}}</span>
      </a>
      <mat-divider *ngIf="menu.url === '-'"></mat-divider>
    </ng-container>
  </mat-menu>
</ng-container>
-->