import { HeaderMenu } from 'src/app/app/models/header-menu.models';
import { VereinSmall } from '../../app/models/verein-small.models';
import { Url } from 'src/app/app/models/url';
import { UiDefinition } from 'src/app/app/models/ui-definition';
import { DataEntry } from 'src/app/shared/models/data-entry';
import { ClubDetailComponent } from 'src/app/club/controls/club-detail/club-detail.component';
import { ClubEntry } from 'src/app/app/models/club-entry.models';
import { HeaderMenu2 } from 'src/app/app/models/header-menu2.models';

export const AUTH_FEATURE_KEY = 'auth';

export enum TokenStatus {
  PENDING = 'PENDING',
  VALIDATING = 'VALIDATING',
  VALID = 'VALID',
  INVALID = 'INVALID',
}

export interface ResponseErrorDetail {
  success: boolean;
  errorCode: string;
  errorMessage: string;
}

export class ResponseError extends Error {
  constructor(
    msg: string,
    private statusCode?: number, // optional variable
  ) {
    super(msg);
    Object.setPrototypeOf(this, ResponseError.prototype);
  }

  public error: ResponseErrorDetail;

}

export interface AuthState {
  isLoggedInChecked: boolean;
  isLoggedIn: boolean;
  user?: AuthUser;
  accessTokenStatus: TokenStatus;
  refreshTokenStatus: TokenStatus;
  isLoadingLogin: boolean;
  hasLoginError: boolean;
}

export interface AuthUser {
  sessionGuid: string;
  mail: string;
  firstName: string;
  lastName: string;
  availableClubs: VereinSmall[];
  selectedClub: VereinSmall;
  firstClub: ClubEntry;
  headerMenus: HeaderMenu[];
  urls: Url[];
  uiDefinitions: any[];
  global: DataEntry[];
  headerMenu2: HeaderMenu2[];
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthState;
}

export const initialState: AuthState = {
  isLoggedInChecked: false,
  isLoggedIn: false,
  user: undefined,
  accessTokenStatus: TokenStatus.PENDING,
  refreshTokenStatus: TokenStatus.PENDING,
  isLoadingLogin: false,
  hasLoginError: false,
};
