import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as AuthActions from './auth.actions';
import * as AuthSelectors from './auth.selectors';
import { SignupRequest } from '../models/signup-request.models';
import { VereinSmall } from '../../app/models/verein-small.models';
import { UiAction } from 'src/app/dynamic-ui/models/ui-action.models';
import { Observable } from 'rxjs';

@Injectable()
export class AuthFacade {

  auth$ = this.authStore.select(AuthSelectors.authQuery);
  user$ = this.authStore.select(AuthSelectors.selectAuthUser);
  isLoggedIn$ = this.authStore.select(AuthSelectors.selectIsLoggedIn);
  isLoadingLogin$ = this.authStore.select(AuthSelectors.selectIsLoadingLogin);
  hasLoginError$ = this.authStore.select(AuthSelectors.selectLoginError);
  loggedInUser$ = this.authStore.select(AuthSelectors.selectAuthUser);

  global$ = this.authStore.select(AuthSelectors.selectGlobal);

  availableClubs$ = this.authStore.select(AuthSelectors.getAvailableClubs);
  selectedClub$ = this.authStore.select(AuthSelectors.getSelectedClub);
  selectedClubName$ = this.authStore.select(AuthSelectors.getSelectedClubName);
  headerMenus$ = this.authStore.select(AuthSelectors.getHeaderMenus);
  headerMenu2$ = this.authStore.select(AuthSelectors.getHeaderMenu2);
  urls$ = this.authStore.select(AuthSelectors.getUrls);
  uiDefinitions$ = this.authStore.select(AuthSelectors.getUiDefinitions);

  selectHeaderMenu2FromRoor$ = this.authStore.select(AuthSelectors.selectHeaderMenu2FromRoot);

  constructor(private authStore: Store) { }

  login(username: string, password: string, rememberThisUser: boolean) {
    this.authStore.dispatch(AuthActions.login({ username, password, rememberThisUser }));
  }

  logout() {
    this.authStore.dispatch(AuthActions.logout());
  }

  signUp(logonName: string, password: string, passwordConfirm: string) {
    const signup: SignupRequest = {
      logonName: logonName,
      password: password,
      confirmPassword: passwordConfirm
    };
    this.authStore.dispatch(AuthActions.signup({ signUpRequest: signup }));
  }

  getAuthUser() {
    this.authStore.dispatch(AuthActions.getAuthUserRequest());
  }

  refreshToken() {
    this.authStore.dispatch(AuthActions.refreshToken());
  }

  selectClub(selection: VereinSmall) {
    this.authStore.dispatch(AuthActions.selectClub({ selection: selection }));
  }

  SelectGetGlobalData(name: string, path: string): any {
    let value: any;
    (this.authStore.select(AuthSelectors.getData(name, path))).subscribe(c => value = c);
    return value;
  }

  selectHeaderMenu2FromParentId(parentId: number){
    return this.authStore.select(AuthSelectors.selectHeaderMenu2FromParentId(parentId));
  }
}
